import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import "./Billing.css";

function Billing() {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state || {};
  const user = JSON.parse(localStorage.getItem("user"));

  const [popupMessage, setPopupMessage] = useState(null);

  useEffect(() => {
    document.body.classList.add("billing-page");
    return () => document.body.classList.remove("billing-page");
  }, []);

  if (!plan) {
    return <p>No plan selected. Please go back to the Pricing page.</p>;
  }

  // Debugging: Ensure PayPal Client ID and Plan ID are loaded
  console.log("PAYPAL_CLIENT_ID:", process.env.REACT_APP_PAYPAL_CLIENT_ID);
  console.log("Selected Plan:", plan.name);

  // Ensure PayPal Client ID is set
  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID || "";
  if (!paypalClientId) {
    console.error("Missing REACT_APP_PAYPAL_CLIENT_ID. Ensure it's set in .env file.");
  }

  // PayPal script configuration
  const initialOptions = {
    "client-id": paypalClientId,
    currency: "USD",
    intent: "subscription",
    vault: true,
    locale: "en_US", // Force UI to English
  };

  // Map plan names to environment variables
  const planIds = {
    "Starter": process.env.REACT_APP_PAYPAL_AGENCY_STARTER,
    "Growth": process.env.REACT_APP_PAYPAL_AGENCY_GROWTH,
    "Pro": process.env.REACT_APP_PAYPAL_AGENCY_PRO,
  };

  const selectedPlanId = planIds[plan.name]; // Get the Plan ID for the selected plan

  // Debugging: Ensure the correct plan ID is used
  console.log("Selected Plan ID:", selectedPlanId);
  if (!selectedPlanId) {
    console.error(`No PayPal Plan ID found for plan: ${plan.name}`);
  }

  const handleApprove = (data) => {
    // Removed the success message line
    // setPopupMessage("Subscription created successfully!");

    const subscriptionData = {
      customerId: user._id,
      plan: `${user.userType}-${plan.name.toLowerCase()}`,
      price: plan.price,
      emails: plan.emails,
      createdAt: new Date().toISOString(),
    };

    if (user.userType === "agency") {
      subscriptionData.youtubers = plan.youtubers || 0;
    }

    fetch("/api/subscriptions", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(subscriptionData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.message || "Failed to save subscription");
          });
        }
        return response.json();
      })
      .then(() => {
        if (user.userType === "agency") {
          navigate("/insert-channels", { state: { plan } });
        } else if (user.userType === "youtuber") {
          navigate("/generate", { state: { plan } });
        } else {
          setPopupMessage("Unknown user type. Please contact support.");
        }
      })
      .catch((err) => {
        console.error("Error saving subscription:", err);
        setPopupMessage("Error saving subscription. Please contact support.");
      });
  };

  return (
    <div className="billing-page-container">
      <Header activePage="emails" />
      <div className="cards-container">
        <div className="card">
          <h2>{plan.name} Plan</h2>
          <h4 className="email-amount">
            {plan.youtubers} YouTubers, {plan.emails} emails each
          </h4>
          <h3 className="price">{`$${plan.price}/mo`}</h3>
          <div className="paypal-section">
            {paypalClientId && selectedPlanId ? (
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createSubscription={(data, actions) => {
                    return actions.subscription.create({
                      plan_id: selectedPlanId,
                      subscriber: {
                        name: {
                          given_name: user.firstName || "John",
                          surname: user.lastName || "Doe"
                        },
                        email_address: user.email || "test@example.com",
                        shipping_address: {
                          name: {
                            full_name: user.firstName + " " + user.lastName || "John Doe"
                          },
                          address: {
                            address_line_1: "123 Main St",
                            admin_area_2: "San Francisco",
                            admin_area_1: "CA",
                            postal_code: "94107",
                            country_code: "US"
                          }
                        }
                      }
                    });
                  }}
                  onApprove={(data, actions) => {
                    handleApprove(data);
                  }}
                  onError={(err) => {
                    setPopupMessage("Subscription creation failed. Please try again.");
                    console.error("PayPal subscription error:", err);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <p className="error-message">
                PayPal is not available. Please check your configuration.
              </p>
            )}
          </div>
          <p className="cancel-notice">
            You can cancel anytime in your profile settings.
          </p>
        </div>
        {popupMessage && <p className="popup-message">{popupMessage}</p>}
      </div>
    </div>
  );
}

export default Billing;