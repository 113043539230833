import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './assets/animation.json';
import './IntroSection.css';

function IntroSection() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Simulating user authentication check (replace with actual authentication logic)
    const loggedInUser = localStorage.getItem('user'); // Example storage method
    setUser(loggedInUser ? JSON.parse(loggedInUser) : null);
  }, []);

  const handleClick = () => {
    if (user) {
      navigate('/generate');
    } else {
      navigate('/auth?mode=signup', { state: { redirectTo: '/generate' } });
    }
  };

  return (
    <div className="intro-section">
      {/* Illustration Container */}
      <div className="illustration-container">
        <div className="lottie-wrapper">
          <Player autoplay loop src={animationData} className="lottie-player" />
        </div>
      </div>
      {/* Content Section */}
      <div className="content">
        <h2 className="homepage-h2">Automate your sponsorship outreach</h2>
        <p className="intro-text">
          Rubybeam helps influencer agencies save time by generating personalized outreach emails in minutes.
        </p>

        {/* CTA Section */}
        <div className="cta-section">
          <button className="cta-button" onClick={handleClick}>
            {user ? 'Generate Emails' : 'Generate 1 Email for Free'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;