import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';
import Header from './Header';
import UserContext from './UserContext'; // Import your UserContext

function Pricing() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Retrieve the user from context (or localStorage/session)

  useEffect(() => {
    document.body.classList.add('pricing-page');
    return () => document.body.classList.remove('pricing-page');
  }, []);

  // Agency plans
  const agencyPlans = [
    { name: 'Starter', price: 300, emails: 10, youtubers: 3 },
    { name: 'Growth', price: 600, emails: 15, youtubers: 5 },
    { name: 'Pro', price: 1000, emails: 20, youtubers: 10 },
  ];

  const handleGetStarted = (plan) => {
    if (user) {
      // User is logged in, redirect to Billing page
      navigate('/billing', { state: { plan } });
    } else {
      // User is not logged in, redirect to Sign-Up page and pass the plan and redirectTo
      navigate('/auth', { state: { redirectTo: '/pricing', plan } });
    }
  };

  return (
    <div className="page-container">
      <Header activePage="pricing" />
      <h2 className="intro-h2">
        Each month, <br />
        you will receive a link to your ready-to-send emails. <br />
        Select the plan that matches your outreach goals.
      </h2>
      <p className="intro-p">
        The more emails you send, the better your chances of securing valuable sponsorship deals.
      </p>
      <div className="cards-container">
        {agencyPlans.map((plan) => (
          <div className="card" key={plan.name}>
            <h2>{plan.name}</h2>
            <h4 className="email-amount">
              {`${plan.youtubers} YouTubers, ${plan.emails} emails each`}
            </h4>
            <h3 className="price">{`$${plan.price}/mo`}</h3>
            <button
              className="buy-button"
              onClick={() => handleGetStarted(plan)} // Redirects based on login status
            >
              Get Started
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;