import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './GenerateEmails.css';
import './Modal.css'; // For modal styling
import Header from './Header';
import axios from 'axios';

function GenerateEmails() {
  const [userData, setUserData] = useState(null);
  const [subscription, setSubscription] = useState(undefined);
  const [emails, setEmails] = useState(0);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [urlError, setUrlError] = useState('');

  // For "processing" messages during polling
  const processingMessages = [
    'Gathering Data',
    'Analyzing Audience',
    'Matching with Brands',
    'Analyzing Brands',
    'Crafting Personalized Pitches',
    'Finalizing Emails',
  ];
  const [processingMessage, setProcessingMessage] = useState(processingMessages[0]);
  const messageIndexRef = useRef(0);

  // For progress bar on paid plans
  const [progress, setProgress] = useState(0);
  const progressIntervalRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchUserData(user._id);
    } else {
      navigate('/sign-up');
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      const userResponse = await axios.get(`/api/users/${userId}`);
      const subscriptionResponse = await axios
        .get(`/api/subscriptions/${userId}/active`)
        .catch((error) => {
          if (error.response?.status === 404) {
            return null; // No active subscription
          }
          throw error;
        });

      setUserData(userResponse.data);
      setSubscription(subscriptionResponse?.data || null);
      setEmails(subscriptionResponse?.data?.emails || 0);
    } catch (error) {
      console.error('Error fetching user or subscription data:', error);
      setFeedbackMessage('An error occurred while fetching data.');
    }
  };

  const validateYoutubeUrl = (url) => {
    // Check if the URL starts with the correct base and has no additional segments
    const validFormat = /^https:\/\/www\.youtube\.com\/@[\w-]+$/;
    if (!validFormat.test(url)) {
      setUrlError(
        'The URL must follow this format: https://www.youtube.com/@username with no additional paths'
      );
      return false;
    }
    setUrlError('');
    return true;
  };

  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
    validateYoutubeUrl(e.target.value);
  };

  // For each plan, specify the "display" string and total seconds for progress bar
  const planDurations = {
    'agency-basic': { label: '15 minutes', seconds: 15 * 60 },
    'agency-plus': { label: '1 hour and 15 minutes', seconds: 75 * 60 },
    'agency-pro': { label: '1 hour and 40 minutes', seconds: 100 * 60 },
  };

  /**
   * Called when user hits the "Generate Emails" button (either free-trial or paid plan).
   * For free-trial, prompts the channel URL modal. 
   * For paid plans, if no lastUpdate, we just proceed directly 
   * (you can adjust if you still want a modal).
   */
  const handleGenerateEmailsClick = () => {
    if (subscription.plan === 'free-trial') {
      // Must validate YT URL
      if (!validateYoutubeUrl(youtubeUrl)) {
        return;
      }
      setModalVisible(true); // Open the modal
    } else {
      // For paid plan, no lastUpdate => generate immediately (or show a similar modal if you prefer)
      handleModalContinue(); 
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleModalContinue = async () => {
    setModalVisible(false);

    if (!userData || !subscription) {
      console.error('User or subscription data not found.');
      return;
    }

    // For free-trial, ensure valid URL
    if (subscription.plan === 'free-trial' && !validateYoutubeUrl(youtubeUrl)) {
      return;
    }

    setLoading(true);
    setFeedbackMessage('');

    // Start the local progress bar if it's a paid plan
    if (subscription.plan !== 'free-trial') {
      startProgressBar(planDurations[subscription.plan]?.seconds || 900);
    }

    try {
      // Make the request to generate emails
      const response = await axios.post('/api/generate-emails', {
        customerId: userData._id,
        youtubeUrl: subscription.plan === 'free-trial' ? youtubeUrl.trim() : '',
      });

      console.log('Emails generation request sent:', response.data);

      // Start polling for completed emails
      pollForEmails(userData._id);
    } catch (error) {
      console.error('Error generating emails:', error);
      setFeedbackMessage('An error occurred while generating emails.');
      setLoading(false);
      clearProgressBar();
    }
  };

  /**
   * Simple polling logic: check every 5s if new emails are ready.
   * If found, stop everything and navigate.
   * If not found within 30 tries (~2.5 min), show an error.
   */
/**
 * Poll for emails indefinitely until at least one email is found.
 */
const pollForEmails = async (
  userId,
  attempt = 1,
  interval = 5000
) => {
  console.log(`Polling attempt #${attempt}...`);
  try {
    // Update "processingMessage" every ~15 seconds (about every 3 attempts at 5s)
    if (attempt % 3 === 0) {
      messageIndexRef.current = Math.min(
        messageIndexRef.current + 1,
        processingMessages.length - 1
      );
      setProcessingMessage(processingMessages[messageIndexRef.current]);
    }

    // Check the server for newly generated emails
    const response = await axios.get(`/api/emails/${userId}`);
    const fetchedEmails = response.data.emails;

    // If there's at least 1 email, navigate away and stop polling
    if (fetchedEmails && fetchedEmails.length > 0) {
      console.log('Emails are ready. Navigating to emails page...');
      setLoading(false);
      clearProgressBar();
      navigate('/emails');
      return; // Stop here
    }

    // Not ready yet. Schedule another poll in `interval` ms.
    setTimeout(() => {
      pollForEmails(userId, attempt + 1, interval);
    }, interval);
  } catch (error) {
    console.error('Error checking email status:', error);
    setLoading(false);
    clearProgressBar();
    setFeedbackMessage('Failed to verify email readiness.');
  }
};


  /**
   * Starts a local progress bar that fills over `totalSeconds`.
   */
  const startProgressBar = (totalSeconds) => {
    setProgress(0);
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    let elapsed = 0;
    progressIntervalRef.current = setInterval(() => {
      elapsed++;
      setProgress(((elapsed / totalSeconds) * 100).toFixed(2));
      if (elapsed >= totalSeconds) {
        clearInterval(progressIntervalRef.current);
      }
    }, 1000);
  };

  const clearProgressBar = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
    setProgress(0);
  };

  // Render logic
  return (
    <div className="generate-emails-outer-container">
      <Header />
      <div className="generate-emails-container">
        {userData ? (
          subscription === undefined ? (
            <p>Loading...</p>
          ) : subscription ? (
            <>
              {/* Common welcome for any subscription */}
              <h2>Welcome {userData.firstName}</h2>

              {/* 1) If subscription plan is NOT free-trial */}
              {subscription.plan !== 'free-trial' ? (
                subscription.lastPeriodEmailsSent ? (
                  // lastPeriodEmailsSent exists => show "notify by email"
                  <div className="no-subscription-container">
                    <h3>
                      We will notify you by email once your monthly emails are ready.
                    </h3>
                  </div>
                ) : (
                  // lastUpdate does NOT exist => show processing time & generate button
                  <div className="no-subscription-container">
                    <h3>
                      Processing time: ~
                      {planDurations[subscription.plan]
                        ? planDurations[subscription.plan].label
                        : '15 minutes'}
                    </h3>
                    {loading ? (
                      <div className="loading-container">
                        {/* Display dynamic messages if desired */}
                        <p className="loading-message">{processingMessage}...</p>

                        {/* Progress bar for paid plans */}
                        <div className="progress-bar-container">
                          <div
                            className="progress-bar"
                            style={{ width: `${progress}%` }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <button onClick={handleGenerateEmailsClick}>Generate Emails</button>
                    )}
                  </div>
                )
              ) : (
                /* 2) If subscription plan IS free-trial */
                <>
                  {/* Check how many free-trial emails remain */}
                  {emails === 0 ? (
                    <div className="no-subscription-container">
                      <h3>
                        You've used all your free emails. Please subscribe to continue
                        generating new emails.
                      </h3>
                      <button onClick={() => navigate('/pricing')}>Upgrade Subscription</button>
                    </div>
                  ) : (
                    <>
                      <h3>
                        You have {emails} {emails === 1 ? 'email' : 'emails'} remaining
                      </h3>
                      <div className="youtube-input-container">
                        <label>
                          Enter a YouTube channel URL:
                          <input
                            type="url"
                            value={youtubeUrl}
                            onChange={handleYoutubeUrlChange}
                            placeholder="https://www.youtube.com/@username"
                            required
                          />
                        </label>
                        {urlError && <p className="error-message">{urlError}</p>}
                      </div>

                      {loading ? (
                        <div className="loading-container">
                          <p className="loading-message">{processingMessage}...</p>
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        <button onClick={handleGenerateEmailsClick} disabled={!!urlError}>
                          Generate Emails
                        </button>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Show any feedback/error messages */}
              {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
            </>
          ) : (
            // No subscription at all
            <div className="no-subscription-container">
              <h2>Welcome {userData.firstName}</h2>
              <h3>To start creating new emails, please purchase a subscription.</h3>
              <button onClick={() => navigate('/pricing')}>Buy Subscription</button>
            </div>
          )
        ) : (
          <p className="loading-message">Loading...</p>
        )}
      </div>

      {/* Confirmation modal (used for free-trial in this example) */}
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>
              Before we can generate personalized emails, please ensure the following
              conditions are met for the YouTuber:
            </h3>
            <p>1. Has at least one long-form video.</p>
            <p>2. Speaks English in the videos.</p>
            <div className="modal-actions">
              <button onClick={handleModalClose} className="cancel-button">
                Cancel
              </button>
              <button
                onClick={handleModalContinue}
                className="continue-button"
                disabled={!!urlError}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GenerateEmails;