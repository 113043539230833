import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';
import './InsertChannels.css';
import Header from './Header';

function InsertChannels() {
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [channels, setChannels] = useState([]);
  const [channelLimit, setChannelLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [urlErrors, setUrlErrors] = useState([]); // Store errors per input
  const [generalError, setGeneralError] = useState(''); // General validation message

  useEffect(() => {
    const checkUserAndFetchData = async () => {
      try {
        if (!user) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            setUser(JSON.parse(storedUser));
          } else {
            localStorage.setItem('redirectPath', location.pathname);
            navigate('/auth');
            return;
          }
        }
  
        if (user?._id) {
          const subRes = await fetch(`/api/subscriptions/${user._id}/active`);
          if (!subRes.ok) throw new Error('Failed to fetch subscription details.');
          const subData = await subRes.json();
  
          if (subData.status !== 'active' || subData.plan === 'free-trial') {
            alert('You need an active subscription (not free-trial) to access this page.');
            navigate('/subscription');
            return;
          }
  
          const limit = user.userType === 'agency' ? subData.youtubers : subData.emails;
          setChannelLimit(limit);
          setUrlErrors(Array(limit).fill('')); // Initialize errors array
  
          const urlRes = await fetch(`/api/urls/${user._id}`, { cache: 'no-store' });
          if (!urlRes.ok) throw new Error(`Failed to fetch URLs.`);
          const urlData = await urlRes.json();
  
          const filledChannels = new Array(limit).fill('');
          if (urlData.urls && urlData.urls.length > 0) {
            urlData.urls.forEach((url, i) => {
              if (i < limit) filledChannels[i] = url;
            });
          }
          setChannels(filledChannels);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        alert('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    checkUserAndFetchData();
  }, [user, setUser, navigate, location.pathname]);

  const validateYoutubeUrl = (url, index) => {
    const validFormat = /^https:\/\/www\.youtube\.com\/@[\w-]+$/;
    const newErrors = [...urlErrors];

    if (!validFormat.test(url)) {
      newErrors[index] = 'The URL must follow this format: https://www.youtube.com/@username';
      setGeneralError(newErrors[index]); // Display first error message above save button
    } else {
      newErrors[index] = ''; 
      setGeneralError(newErrors.find(error => error !== '') || ''); // Clear general error if no errors left
    }

    setUrlErrors(newErrors);
    return validFormat.test(url);
  };

  const handleChannelsChange = (index, value) => {
    const updatedChannels = [...channels];
    updatedChannels[index] = value;
    setChannels(updatedChannels);
    validateYoutubeUrl(value, index);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all URLs before submitting
    const allValid = channels.every((channel, index) => validateYoutubeUrl(channel, index));

    if (!allValid) {
      return;
    }

    try {
      const saveRes = await fetch(`/api/urls/${user._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ urls: channels.filter(channel => channel.trim() !== '') }),
      });

      if (!saveRes.ok) throw new Error('Failed to update URLs.');
      navigate('/generate');
    } catch (error) {
      console.error('Error saving channels:', error.message);
      alert('Failed to save channels. Please try again.');
    }
  };

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="insert-channels-container">
      <Header activePage="emails" />
      <h1 className="insert-channels-header">Insert Your Channels URLs</h1>
      <form onSubmit={handleSubmit} className="insert-channels-form">
        <div className="insert-channels-columns">
          {channels.map((channel, index) => (
            <div key={index} className="insert-channels-input-group">
              <label className="insert-channels-label">{index + 1}.</label>
              <input
                type="text"
                className={`insert-channels-input ${urlErrors[index] ? 'error-border' : ''}`}
                value={channel}
                onChange={(e) => handleChannelsChange(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        {generalError && <p className="error-message">{generalError}</p>}
        <button type="submit" className="insert-channels-button">
          Save
        </button>
      </form>
    </div>
  );
}

export default InsertChannels;