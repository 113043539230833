import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import UserContext from './UserContext';

function Header({ activePage }) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // Close mobile menu after navigation
  };

  const handleSignOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
    setMenuOpen(false);
  };

  const handleGenerateEmailsClick = () => {
    if (user) {
      navigate('/generate');
    } else {
      navigate('/auth?mode=signup', { state: { redirectTo: '/generate' } });
    }
    setMenuOpen(false);
  };

  return (
    <header
      className="header"
      style={{
        backgroundColor: activePage === 'pricing' || activePage === 'emails' ? '#f3f5fc' : '#ffffff',
      }}
    >
      {/* Logo */}
      <div className="logo" onClick={() => handleNavigation('/')}>
        Rubybeam
      </div>

      {/* Hamburger Menu (Only Visible on Mobile) */}
      <button className="hamburger-menu mobile-only" onClick={() => setMenuOpen(!menuOpen)}>
        ☰
      </button>

      {/* Mobile Menu Dropdown */}
      <div className={`mobile-menu ${menuOpen ? 'active' : ''}`}>
        <button onClick={() => handleNavigation('/')}>Home</button>
        <button onClick={() => handleNavigation('/how-it-works')}>How It Works</button>
        <button onClick={() => handleNavigation('/pricing')}>Pricing</button>
        <button onClick={() => handleNavigation('/contact')}>Contact Us</button>
        {user ? (
          <>
            <button onClick={() => handleNavigation('/emails')}>New Emails</button>
            <button onClick={() => handleNavigation('/dashboard')}>Dashboard</button>
            <button onClick={() => handleNavigation('/settings')}>Settings</button>
            <button onClick={handleSignOut}>Sign out</button>
          </>
        ) : (
          <button onClick={() => handleNavigation('/auth?mode=login')}>Log In</button>
        )}
      </div>

      {/* Desktop Navigation (Hidden on Mobile) */}
      <nav className="nav-links">
        <button
          className={`header-button ${activePage === 'home' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/')}
        >
          Home
        </button>
        <button
          className={`header-button ${activePage === 'how-it-works' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/how-it-works')}
        >
          How It Works
        </button>
        <button
          className={`header-button ${activePage === 'pricing' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/pricing')}
        >
          Pricing
        </button>
        <button
          className={`header-button ${activePage === 'contact' ? 'header-button-active' : ''}`}
          onClick={() => handleNavigation('/contact')}
        >
          Contact Us
        </button>
      </nav>

      {/* Authentication & CTA Buttons (Hidden on Mobile) */}
      <div className="auth-buttons">
        {user ? (
          <div className="user-menu">
            <button className="user-name-button">{user.firstName}</button>
            <div className="dropdown-menu">
              <button onClick={() => handleNavigation('/emails')}>New Emails</button>
              <button onClick={() => handleNavigation('/dashboard')}>Dashboard</button>
              <button onClick={() => handleNavigation('/settings')}>Settings</button>
              <button onClick={handleSignOut}>Sign out</button>
            </div>
          </div>
        ) : (
          <button
            className="header-button header-sign-in"
            onClick={() => handleNavigation('/auth?mode=login')}
          >
            Log In
          </button>
        )}
        <button
          className="header-button header-get-started desktop-only"
          onClick={handleGenerateEmailsClick}
        >
          Generate Emails
        </button>
      </div>
    </header>
  );
}

export default Header;