// Emails.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import './Emails.css';
import Header from './Header';
import axios from 'axios';
import { marked } from 'marked';


function Emails() {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [expandedChannels, setExpandedChannels] = useState({});
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [isYoutuberDropdownOpen, setIsYoutuberDropdownOpen] = useState(false);
  const [selectedYoutuber, setSelectedYoutuber] = useState('');
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 960);
  const [isYoutuberPopupOpen, setIsYoutuberPopupOpen] = useState(false);
  const [isBrandPopupOpen, setIsBrandPopupOpen] = useState(false);
  const [neededCount, setNeededCount] = useState(null);



  const contentEditableRef = useRef(null);

  const groupedEmails = useMemo(() => {
    const groups = {};
    emails.forEach((email) => {
      const channelName = email.channelName || 'Unknown Channel';
      if (!groups[channelName]) {
        groups[channelName] = [];
      }
      groups[channelName].push(email);
    });
    return groups;
  }, [emails]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) return;

    // 1) On mount, fetch the latest request
    fetchLastRequest(user._id);

    // 2) Also fetch the existing emails once immediately
    fetchEmails(user._id);
  }, []);

  const fetchLastRequest = async (userId) => {
    try {
      const res = await axios.get(`/api/subscriptions/${userId}/active`);
  
      if (res.data) {
        const emailsPerYoutuber = res.data.emails || 10;
        const youtubers = res.data.youtubers || 3;
        const totalEmails = emailsPerYoutuber * youtubers;
  
        console.log("Fetched from active subscription:", {
          emailsPerYoutuber,
          youtubers,
          totalEmails
        });
  
        setNeededCount(totalEmails);
      } else {
        console.warn("No active subscription found, defaulting neededCount to 30.");
        setNeededCount(30);
      }
    } catch (err) {
      console.error("Error fetching active subscription:", err);
      setNeededCount(30); // Ensure a fallback value in case of an error
    }
  };
  
  
  

  useEffect(() => {
    if (emails.length > 0 && Object.keys(expandedChannels).length === 0) {
      const initialExpandedChannels = {};
      Object.keys(groupedEmails).forEach((channelName, index) => {
        initialExpandedChannels[channelName] = index === 0; // Only the first YouTuber's channel is expanded
      });
      setExpandedChannels(initialExpandedChannels);

      // Automatically select the first YouTuber and its first email
      const firstChannelName = Object.keys(groupedEmails)[0];
      const firstEmail = groupedEmails[firstChannelName]?.[0];
      if (firstEmail) {
        setSelectedEmail(firstEmail);
      }
    }
  }, [emails, groupedEmails, expandedChannels]);




  useEffect(() => {
    document.body.classList.add('emails-page');
    return () => document.body.classList.remove('emails-page');
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchEmails(user._id);
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) return;
  
    // Keep polling until we get all needed emails
    if (neededCount === null || emails.length < neededCount) {
      const intervalId = setInterval(() => {
        fetchEmails(user._id);
      }, 5000); // Reduce polling interval for faster updates
  
      return () => clearInterval(intervalId);
    }
  }, [neededCount, emails]);
  
  


  const sendEmail = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      await axios.post('/api/send-email', {
        emailId: selectedEmail._id,
        to: selectedEmail.to,
        subject: selectedEmail.subject,
        content: selectedEmail.content,
        userId: user._id,
      });

      alert('Email sent successfully!');
      fetchEmails(user._id);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }, [selectedEmail]); // Add `selectedEmail` as a dependency


  const sendAllEmails = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      await axios.post('/api/send-all-emails', {
        userId: user._id,
        emails, // Send the updated emails
      });

      alert('All emails sent successfully!');
      fetchEmails(user._id);
    } catch (error) {
      console.error('Error sending all emails:', error);
      alert('Failed to send all emails.');
    }
  }, [emails]); // Add `emails` as a dependency


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if (status === 'success') {
      setAuthenticated(true);

      const pendingAction = localStorage.getItem('pendingAction');
      if (pendingAction === 'sendAllEmails') {
        localStorage.removeItem('pendingAction');
        sendAllEmails();
      } else if (pendingAction === 'sendEmail') {
        localStorage.removeItem('pendingAction');
        sendEmail();
      } else {
        alert('Email sent successfully!');
      }
    } else if (status === 'failed') {
      alert('Failed to send email.');
    }

    window.history.replaceState({}, document.title, '/emails');
  }, [sendAllEmails, sendEmail]);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 960);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEmailFieldChange = (field, value) => {
    setSelectedEmail((prevEmail) => ({
      ...prevEmail,
      [field]: value,
    }));
  };

  const handleYoutuberSelect = (channelName) => {
    setSelectedYoutuber(channelName);
    setIsYoutuberPopupOpen(false); // Close popup
  };

  const handleBrandSelect = (brandName, email) => {
    setSelectedBrand(brandName);
    setSelectedEmail(email);
    setIsBrandPopupOpen(false); // Close popup
  };

  const fetchEmails = async (userId) => {
    try {
      const response = await axios.get(`/api/emails/${userId}`);
      
      if (response.data && Array.isArray(response.data.emails)) {
        // Filter out emails with status "sent"
        const filteredEmails = response.data.emails.filter(
          (email) => email.status !== "sent"
        );
  
        const emailsWithHTMLContent = filteredEmails.map((email) => ({
          ...email,
          content: marked(email.content, { breaks: true }),
        }));
  
        setEmails(emailsWithHTMLContent);
  
        // Keep the current selection if it's still valid
        setSelectedEmail((currentSelectedEmail) => {
          if (
            currentSelectedEmail &&
            emailsWithHTMLContent.some(
              (email) => email._id === currentSelectedEmail._id
            )
          ) {
            return currentSelectedEmail; // Keep the current selection
          } else {
            return emailsWithHTMLContent[0] || null; // Select the first email if no valid selection
          }
        });
  
        // If fewer than neededCount emails are present, keep polling
        if (emailsWithHTMLContent.length < neededCount) {
          console.log(
            `Fetched ${emailsWithHTMLContent.length}/${neededCount} emails. Polling again...`
          );
          setTimeout(() => fetchEmails(userId), 5000); // Poll every 5s until all emails are received
        } else {
          console.log("All required emails have been fetched.");
        }
      } else {
        console.log("No emails found, retrying...");
        setEmails([]);
        setSelectedEmail(null);
  
        // Retry fetching if no emails are found
        setTimeout(() => fetchEmails(userId), 5000);
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
      setEmails([]);
      setSelectedEmail(null);
  
      // Retry fetching in case of an error
      setTimeout(() => fetchEmails(userId), 5000);
    }
  };
  



  const handleToggleChannel = (channelName) => {
    setExpandedChannels((prevState) => ({
      ...prevState,
      [channelName]: !prevState[channelName],
    }));
  };

  const fetchAuthUrl = async () => {
    try {
      if (!selectedEmail) {
        alert('No email selected!');
        return;
      }

      const { to, subject, content, _id } = selectedEmail;
      const user = JSON.parse(localStorage.getItem('user'));

      const response = await axios.get(
        `/api/auth/gmail?to=${encodeURIComponent(
          to
        )}&subject=${encodeURIComponent(
          subject
        )}&content=${encodeURIComponent(
          content
        )}&userId=${encodeURIComponent(user._id)}&emailId=${encodeURIComponent(_id)}`
      );
      const authUrl = response.data.authUrl;

      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };

  const fetchAuthUrlForAuth = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.get(
        `/api/auth/gmail?userId=${encodeURIComponent(user._id)}`
      );
      const authUrl = response.data.authUrl;

      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };



  const handleSendEmail = () => {
    if (!authenticated) {
      fetchAuthUrl();
    } else {
      sendEmail();
    }
  };

  const handleSendAllEmails = () => {
    if (!authenticated) {
      localStorage.setItem('pendingAction', 'sendAllEmails');
      fetchAuthUrlForAuth();
    } else {
      sendAllEmails();
    }
  };

  const handlePrevEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const prevIndex = (currentIndex - 1 + emails.length) % emails.length;
    setSelectedEmail(emails[prevIndex]);
  };

  const handleNextEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const nextIndex = (currentIndex + 1) % emails.length;
    setSelectedEmail(emails[nextIndex]);
  };

  const handleContentBlur = () => {
    if (contentEditableRef.current) {
      const updatedContent = contentEditableRef.current.innerHTML;
      handleEmailFieldChange('content', updatedContent);
    }
    setIsEditingContent(false);
  };

  return (
    <div className="root-container">
      <Header activePage="emails" />
      <div className="emails-container">
        {isSmallScreen ? (
          // Boxes for smaller screens
          <div className="youtuber-brand-boxes">
            <div className="youtuber-box" onClick={() => setIsYoutuberPopupOpen(true)}>
              <div className="box-placeholder">
                {selectedYoutuber || 'Choose Youtuber'}
              </div>
            </div>
            <div className="brand-box" onClick={() => setIsBrandPopupOpen(true)}>
              <div className="box-placeholder">{selectedBrand || 'Choose Brand'}</div>
            </div>
          </div>
        ) : (
          // Sidebar for larger screens
          emails.length > 0 && (
          <div className="youtuber-sidebar">
            {Object.keys(groupedEmails).map((channelName) => (
              <div
                key={channelName}
                className={`youtuber-item ${expandedChannels[channelName] ? 'active' : ''}`}
                onClick={() =>
                  setExpandedChannels({ [channelName]: true }) // Only one channel active at a time
                }
              >
                {channelName}
              </div>
            ))}
          </div>

        ))}

        {/* Youtuber Popup */}
        {isYoutuberPopupOpen && (
          <div className="popup-overlay" onClick={() => setIsYoutuberPopupOpen(false)}>
            <div className="popup-modal" onClick={(e) => e.stopPropagation()}>
              <h3>Choose Youtuber</h3>
              <div className="popup-options">
                {Object.keys(groupedEmails).map((channelName) => (
                  <div
                    key={channelName}
                    className="popup-option"
                    onClick={() => handleYoutuberSelect(channelName)}
                  >
                    {channelName}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Brand Popup */}
        {isBrandPopupOpen && (
          <div className="popup-overlay" onClick={() => setIsBrandPopupOpen(false)}>
            <div className="popup-modal" onClick={(e) => e.stopPropagation()}>
              <h3>Choose Brand</h3>
              <div className="popup-options">
                {groupedEmails[selectedYoutuber]?.map((email) => (
                  <div
                    key={email._id}
                    className="popup-option"
                    onClick={() => handleBrandSelect(email.brandName, email)}
                  >
                    {email.brandName}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {emails.length === 0 ? (
          <div className="no-emails-container">
            <div className="no-emails-message">
              <h2>
                You've sent all emails. We will notify you when new emails are
                ready.
              </h2>
              <h3>
                Want more emails?{' '}
                <a href="/settings" className="upgrade-link">
                  Upgrade plan
                </a>
              </h3>
            </div>
          </div>
        ) : (
          <div className="brands-container">
            {Object.entries(groupedEmails).map(([channelName, emailsInChannel]) =>
              expandedChannels[channelName] ? (
                <div key={channelName} className="brand-list">
                  <div className="brand-items">
                    {emailsInChannel.map((email) => (
                      <div
                        key={email._id}
                        className={`brand-item ${selectedEmail && selectedEmail._id === email._id
                            ? 'selected'
                            : ''
                          }`}
                        onClick={() => setSelectedEmail(email)}
                      >
                        {email.brandName}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
            )}
          </div>
        )}

        {selectedEmail && (
          <div className="email-card-wrapper">
            <div className="email-card">
              <div className="email-section">
                <label>To</label>
                <div className="email-input-container">
                  <div className="email-input">{selectedEmail.to}</div>
                </div>
              </div>
              <div className="email-section">
                <label>Subject</label>
                <div className="email-input-container">
                  {isEditingSubject ? (
                    <input
                      type="text"
                      className="email-input"
                      value={selectedEmail.subject}
                      onChange={(e) =>
                        handleEmailFieldChange('subject', e.target.value)
                      }
                      onBlur={() => setIsEditingSubject(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditingSubject(false);
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="email-input"
                      onClick={() => setIsEditingSubject(true)}
                    >
                      {selectedEmail.subject}
                    </div>
                  )}
                </div>
              </div>
              <div className="email-section">
                <label>Content</label>
                <div className="email-textarea-container">
                  {isEditingContent ? (
                    <div
                      className="email-textarea"
                      contentEditable
                      ref={contentEditableRef}
                      onBlur={handleContentBlur}
                      suppressContentEditableWarning
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  ) : (
                    <div
                      className="email-textarea"
                      onClick={() => setIsEditingContent(true)}
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="email-buttons">
              <button
                className="email-button send-email"
                onClick={handleSendEmail}
              >
                Send Email
              </button>

            </div>
          </div>
        )}
      </div>
    </div>
  );

}

export default Emails;